<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { type Project, DocumentDiscriminatorType } from '@/gql/graphql';
import { useNotificationStore } from '@/stores/notification';
import { useUserSettingsStore } from '@/stores/user-settings';
import AppModal from './AppModal.vue';
import { VCardText } from 'vuetify/components';
import DocumentForm, { type DocumentFormValues } from './DocumentForm.vue';
import { ref } from 'vue';

const props = defineProps<{ project: Project }>();
const showDialog = defineModel<boolean>({ default: false });

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:document aanmaken',
            create_success: '@:document succesvol aangemaakt',
        },
        en: {
            title: 'Create @.lower:document',
            create_success: '@:document successfully created',
        },
    },
});

const notificationStore = useNotificationStore();
const userSettingsStore = useUserSettingsStore();

const { mutate: uploadDocument } = useMutation(
    graphql(`
        mutation UploadDocument($input: UploadDocumentInput!) {
            uploadDocument(input: $input) {
                result
            }
        }
    `),
    {
        refetchQueries: ['ProjectDetails'],
        awaitRefetchQueries: true,
    }
);

const isUploading = ref(false);

async function handleCreate(values: DocumentFormValues) {
    try {
        isUploading.value = true;

        const { project } = props;

        const data = await uploadDocument({
            input: {
                parentId: project.id,
                discriminator: DocumentDiscriminatorType.PROJECT,
                accountId: userSettingsStore.activeOrganisation?.id,
                name: values.name,
                description: values.description,
                fileName: values.document.name,
                fileType: values.document.type,
            },
        });

        if (!data?.data?.uploadDocument?.result) {
            throw new Error();
        }

        const fileUploadData = JSON.parse(data.data.uploadDocument.result);
        const fileUploadFormData = Object.entries(fileUploadData.fields).reduce(
            (formData, [key, value]) => (
                formData.append(key, value as string), formData
            ),
            new FormData()
        );

        fileUploadFormData.append('file', values.document as File);

        await fetch(fileUploadData.url, {
            method: 'POST',
            body: fileUploadFormData,
        });

        showDialog.value = false;
        notificationStore.add({
            type: 'success',
            message: t('create_success'),
        });
    } catch {
        notificationStore.add({ type: 'error', message: t('create_error') });
    } finally {
        isUploading.value = false;
    }
}
</script>

<template>
    <AppModal
        v-model="showDialog"
        :title="t('title')"
        :width="744"
        height="auto"
        persistent
    >
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text class="pa-8">
            <DocumentForm
                :disabled="isUploading"
                :loading="isUploading"
                @cancel="showDialog = false"
                @submit="handleCreate"
            />
        </v-card-text>
    </AppModal>
</template>
