<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { graphql } from '@/gql';
import { computed, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { RouteName } from '@/router';
import { format, parseISO } from 'date-fns';
import { usePermissions } from '@/composables/permissions';
import { PolicyAction } from '@/types';
import confetti from 'canvas-confetti';

import DeliverableStatusMenu from './DeliverableStatusMenu.vue';
import DeliverableTypeSymbol from '@/components/DeliverableTypeSymbol.vue';
import DeliverableDeleteDialog from './DeliverableDeleteDialog.vue';
import BottleneckCreateModal from './BottleneckCreateModal.vue';
import EscalationCreateModal from './EscalationCreateModal.vue';
import DeliverableFinishDialog from './DeliverableFinishDialog.vue';
import DeliverableFinishBottlenecksModal from './DeliverableFinishBottlenecksModal.vue';

import type { DeliverableTableSubItem } from './DeliverableTable.vue';
import {
    DeliverableStatus as GqlDeliverableStatus,
    DeliverableState,
} from '@/gql/graphql';
import { useMutation } from '@vue/apollo-composable';
import { useNotificationStore } from '@/stores/notification';

type DeliverableStatus = 'finished' | 'escalation' | 'pending' | 'bottleneck';

const { t } = useI18n({
    messages: {
        nl: {
            delete: 'Verwijderen',
            bottleneck: 'Bottleneck',
            finished: 'Afgerond',
            escalation: 'Escalatie',
            pending: 'Te voltooien',
            finish_success: '@:deliverable is afgerond',
            finish_error:
                'Er is een fout opgetreden bij het afronden van het @.lower:deliverable',
            pending_success: '@:deliverable is geupdatet',
            pending_error:
                'Er is een fout opgetreden bij het updaten van de @.lower:deliverable',
        },
        en: {
            delete: 'Delete',
            bottleneck: 'Bottleneck',
            finished: 'Finished',
            escalation: 'Escalation',
            pending: 'Pending',
            finish_success: '@:deliverable has been finished',
            finish_error:
                'An error occurred while finishing the @.lower:deliverable',
            pending_success: '@:deliverable has been set to pending',
            pending_error:
                'An error occurred while pending the @.lower:deliverable',
        },
    },
});

const notificationStore = useNotificationStore();
const { hasPermission } = usePermissions();

const props = withDefaults(
    defineProps<{
        item: DeliverableTableSubItem;
        canUpdate: boolean;
        canEscalate: boolean;
        subitem?: boolean;
    }>(),
    { subitem: false }
);
const stateLabel = ref<DeliverableStatus | DeliverableState | undefined>(
    undefined
);

const templateState = computed<DeliverableStatus | DeliverableState>(() => {
    return stateLabel.value ?? props.item.state === 'escalation'
        ? 'escalation'
        : props.item.type.status;
});

const {
    mutate: updateDeliverableStatus,
    loading: updateDeliverableStatusLoading,
} = useMutation(
    graphql(`
        mutation UpdateDeliverableStatus(
            $input: UpdateDeliverableStatusInput!
        ) {
            updateDeliverableStatus(input: $input) {
                ok
            }
        }
    `),
    {
        refetchQueries: ['ProjectPhases', 'Deliverable', 'MetroProjects'],
        awaitRefetchQueries: true,
    }
);

async function handleStatusMutation(status: GqlDeliverableStatus) {
    const msg = status === GqlDeliverableStatus.FINISHED ? 'finish' : 'pending';
    try {
        await updateDeliverableStatus({
            input: {
                deliverableId: props.item.id,
                status,
            },
        });

        if (status === GqlDeliverableStatus.FINISHED) {
            confetti();
        }

        notificationStore.add({
            type: 'success',
            message: t(`${msg}_success`),
        });
    } catch {
        notificationStore.add({
            type: 'error',
            message: t(`${msg}_error`),
        });
    }
}

async function handleStatusSelect(newStatus: DeliverableStatus) {
    const hasBottlenecksAndOrEscalations = [
        'bottleneck',
        'escalation',
    ].includes(templateState.value);

    if (newStatus === templateState.value) return;

    switch (newStatus) {
        case 'pending':
            /*
                If there are bottlenecks/escalations the user needs to resolve them in the bottlenecks modal.
                If there aren't, change deliverable status to pending.
            */
            if (hasBottlenecksAndOrEscalations) {
                showDeliverableBottlenecksModal();
            } else if (templateState.value === 'finished') {
                handleStatusMutation(GqlDeliverableStatus.PENDING);
            }
            return;
        case 'bottleneck':
            /*
                If there are bottlenecks/escalations, the user has to change status in the bottlenecks modal
                If there are none yet, show the create bottleneck form
            */
            if (hasBottlenecksAndOrEscalations) {
                showDeliverableBottlenecksModal();
            } else {
                showBottleneckCreateModal();
            }
            return;
        case 'escalation':
            /*
                If there are bottlenecks/escalations, the user has to change status in the bottlenecks modal
                If there are none yet, show the create escalation form
            */
            if (!hasBottlenecksAndOrEscalations) {
                showEscalationCreateModal();
            } else {
                showDeliverableBottlenecksModal();
            }
            return;
        case 'finished':
            /*
                If there are bottlenecks/escalations,the user needs to resolve them in the bottlenecks modal
                If there are none, finish the deliverable
            */
            if (hasBottlenecksAndOrEscalations) {
                showDeliverableBottlenecksModal();
            } else if (templateState.value === 'pending') {
                handleStatusMutation(GqlDeliverableStatus.FINISHED);
            }
            return;
        default:
            console.warn('Unhandled status:', newStatus);
    }
}

const isShowingEscalationCreateModal = ref(false);
const isShowingBottleneckCreateModal = ref(false);
const isShowingDeliverableFinishDialog = ref(false);
const isShowingDeliverableBottlenecksModal = ref(false);
const canUpdateDeliverable = computed(() => {
    return (
        props.canUpdate &&
        (props.item.state !== DeliverableState.ESCALATION || props.canEscalate)
    );
});
function showEscalationCreateModal() {
    isShowingEscalationCreateModal.value = true;
}

function showBottleneckCreateModal() {
    isShowingBottleneckCreateModal.value = true;
}
function showDeliverableBottlenecksModal() {
    isShowingDeliverableBottlenecksModal.value = true;
}

function showDeliverableFinishDialog() {
    isShowingDeliverableFinishDialog.value = true;
}
</script>

<template>
    <EscalationCreateModal
        v-model="isShowingEscalationCreateModal"
        :deliverable-id="item.id"
    />
    <BottleneckCreateModal
        v-model="isShowingBottleneckCreateModal"
        :deliverable-id="item.id"
    />
    <DeliverableFinishBottlenecksModal
        v-model="isShowingDeliverableBottlenecksModal"
        :deliverable-id="item.id"
        :loading="false"
        :can-update="
            hasPermission(
                PolicyAction.PROJECT_WRITE,
                item?.project?.id,
                item?.project?.masterPortfolioId ?? undefined
            )
        "
        :can-delete="
            hasPermission(
                PolicyAction.PROJECT_WRITE,
                item?.project?.id,
                item?.project?.masterPortfolioId ?? undefined
            )
        "
        @all-solved="handleStatusMutation(GqlDeliverableStatus.FINISHED)"
    />
    <DeliverableFinishDialog
        v-model="isShowingDeliverableFinishDialog"
        :deliverable-id="item.id"
        :refetch-queries="['Deliverable', 'ProjectPhases', 'MetroProjects']"
    />
    <tr
        class="v-data-table__tr"
        :class="subitem ? 'v-data-table__tr--expansion' : null"
    >
        <td class="v-data-table__td"></td>
        <td class="v-data-table__td">
            <router-link
                variant="text"
                :to="{
                    name: RouteName['dashboard.deliverable'],
                    params: { id: item.id },
                }"
                class="text-primary text-decoration-none font-weight-bold"
            >
                {{ item.name }}
            </router-link>
        </td>
        <td class="v-data-table__td">
            <DeliverableTypeSymbol
                :milestone="item.type.isMilestone"
                :status="item.type.status"
                :state="item.state"
            />
        </td>
        <td class="v-data-table__td">
            <DeliverableStatusMenu
                v-if="canUpdateDeliverable"
                size="small"
                :project="item.project"
                :label="templateState"
                :current-status="templateState"
                :loading="updateDeliverableStatusLoading"
                @select="handleStatusSelect"
            />
            <span v-else>
                {{ t(templateState) }}
            </span>
        </td>
        <td class="v-data-table__td">
            <template v-if="item.startDate">
                {{ format(parseISO(item.startDate), 'd-M-yyyy') }}
            </template>
        </td>
        <td class="v-data-table__td">
            <template v-if="item.endDate">
                {{ format(parseISO(item.endDate), 'd-M-yyyy') }}
            </template>
        </td>
        <td class="v-data-table__td pl-2">
            <div class="d-flex justify-end">
                <DeliverableDeleteDialog
                    v-if="canUpdateDeliverable"
                    :deliverable-id="item.id"
                >
                    <template #activator="{ props: templateProps, loading }">
                        <v-btn-icon
                            size="x-small"
                            icon="$delete"
                            :aria-label="t('delete')"
                            :loading
                            v-bind="templateProps"
                        />
                    </template>
                </DeliverableDeleteDialog>
            </div>
        </td>
    </tr>
</template>
