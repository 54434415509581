<script lang="ts" setup>
import type { DeliverableStatus, DeliverableState } from '@/gql/graphql';
import { VTooltip } from 'vuetify/components';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DeliverableStatusSymbol from './DeliverableStatusSymbol.vue';

interface DeliverableTypeSymbolProps {
    milestone?: boolean;
    status: DeliverableStatus;
    state: DeliverableState;
}

const { t } = useI18n({
    messages: {
        nl: {
            milestone: 'Milestone',
            finished: 'Afgerond',
            pending: 'Pending',
            bottleneck: 'Bottleneck',
            escalation: 'Escalatie',
        },
        en: {
            milestone: 'Milestone',
            finished: 'Finished',
            pending: 'Pending',
            bottleneck: 'Bottleneck',
            escalation: 'Escalation',
        },
    },
});

const {
    milestone = false,
    status,
    state,
} = defineProps<DeliverableTypeSymbolProps>();

const actualStatus = computed(() => {
    if (state === 'escalation') {
        return 'escalation';
    }

    if (state === 'bottleneck') {
        return 'bottleneck';
    }

    if (status === 'finished') {
        return 'finished';
    }

    return 'pending';
});

const tooltipText = computed(() => {
    const label = t(status);

    if (milestone) {
        return `${t('milestone')} - ${label}`;
    }

    return label;
});
</script>

<template>
    <v-tooltip :text="tooltipText">
        <template #activator="{ props: tooltipProps }">
            <DeliverableStatusSymbol
                :variant="actualStatus"
                :milestone
                v-bind="tooltipProps"
            />
        </template>
    </v-tooltip>
</template>
