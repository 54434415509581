<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { VDataTable } from 'vuetify/components';
import { computed } from 'vue';
import DeliverableTableRow from './DeliverableTableRow.vue';
import PhaseTableRow from './PhaseTableRow.vue';
import type {
    DeliverableState,
    DeliverableStatus,
    Project,
} from '@/gql/graphql';
import { sortByDate } from '@/utils/date';

export interface DeliverableTableItem {
    name: string;
    id: string;
    description?: string;
    deliverables?: DeliverableTableSubItem[];
    startDate?: string;
    endDate: string;
    project: Pick<Project, 'id' | 'masterPortfolioId'>;
}

export interface DeliverableTableSubItem {
    id: string;
    name: string;
    type: {
        isMilestone?: boolean;
        status: DeliverableStatus;
    };
    state: DeliverableState;
    startDate?: string;
    endDate: string;
    project: Pick<Project, 'id' | 'masterPortfolioId'>;
}

defineEmits<{
    phaseDeleted: [];
    loading: [value: boolean];
}>();

const props = withDefaults(
    defineProps<{
        items: (DeliverableTableItem | DeliverableTableSubItem)[];
        itemsPerPage?: number;
        loading?: boolean;
        canUpdate: boolean;
        canEscalate: boolean;
    }>(),
    { itemsPerPage: -1 }
);

function isDeliverableTableItem(input: unknown): input is DeliverableTableItem {
    if (input && typeof input === 'object' && 'deliverables' in input)
        return true;
    return false;
}

const { t } = useI18n({
    messages: {
        nl: {
            phases: 'Fases',
            type: '',
        },
        en: {
            phases: 'Phases',
            type: '',
        },
    },
});

const headers = computed(() => [
    {
        title: t('phases'),
        key: 'phase',
        width: '120px',
    },
    {
        title: t('deliverables'),
        key: 'deliverables',
        width: '240px',
    },
    {
        title: t('type'),
        key: 'type',
        width: '32px',
    },
    {
        title: t('status'),
        key: 'status',
        width: '160px',
    },
    {
        title: t('start_date'),
        key: 'startDate',
        width: '160px',
    },
    {
        title: t('end_date'),
        key: 'endDate',
        width: '160px',
    },
    {
        title: t('actions'),
        key: 'actions',
        sortable: false,
        width: '64px',
    },
]);

const sortedItems = computed(() =>
    props.items.map((item) => {
        if (isDeliverableTableItem(item) && item.deliverables) {
            return {
                ...item,
                deliverables: sortByDate([...item.deliverables], 'endDate'),
            };
        }

        return item;
    })
);
</script>

<template>
    <v-data-table
        :sort-by="[{ key: 'endDate', order: 'asc' }]"
        :items="sortedItems"
        :headers
        :items-per-page
        :loading
    >
        <!-- Phases or Unphased deliverable: root-->
        <template #item="{ item, internalItem, isExpanded, toggleExpand }">
            <PhaseTableRow
                v-if="isDeliverableTableItem(item)"
                :item
                :is-expanded="isExpanded(internalItem)"
                @toggle-expand="toggleExpand(internalItem)"
                @delete="$emit('phaseDeleted')"
            />

            <!-- Deliverables without a phase are rendered separate -->
            <DeliverableTableRow v-else :item :can-update :can-escalate />
        </template>

        <!-- Deliverables: sub row -->
        <template #expanded-row="{ item }">
            <template v-if="isDeliverableTableItem(item)">
                <DeliverableTableRow
                    v-for="deliverable in item.deliverables"
                    :key="deliverable.id"
                    subitem
                    :item="deliverable"
                    :can-update
                    :can-escalate
                />
            </template>
        </template>
        <template v-if="itemsPerPage === -1" #bottom></template>
    </v-data-table>
</template>
