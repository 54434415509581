import { format as _format } from 'date-fns';
import { assertIsString } from './type';

export function formatDate(
    date: string | number | Date,
    format = 'dd/MM/yyyy'
) {
    return _format(date, format);
}

export function sortByDate<T>(
    items: T[],
    key: Extract<keyof T, string>,
    order: 'asc' | 'desc' = 'asc'
) {
    return items.sort((a, b) => {
        assertIsString(a[key]);
        assertIsString(b[key]);
        const dateA = new Date(a[key]).getTime();
        const dateB = new Date(b[key]).getTime();

        return order === 'asc' ? dateA - dateB : dateB - dateA;
    });
}
