<script lang="ts" setup>
import { computed } from 'vue';

export type DeliverableStatusSymbolVariant =
    | 'escalation'
    | 'bottleneck'
    | 'pending'
    | 'finished';

enum DeliverableStatusSymbolColor {
    'escalation' = 'error-40',
    'bottleneck' = 'warning',
    'pending' = 'neutral-70',
    'finished' = 'success',
}

const props = defineProps<{
    variant: DeliverableStatusSymbolVariant;
    milestone?: boolean;
}>();

const color = computed(() => DeliverableStatusSymbolColor[props.variant]);
const classes = computed(() => ['d-flex', `text-${color.value}`]);
</script>

<template>
    <div :class="classes">
        <svg
            v-if="milestone"
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.48535 17.4854L0.25007 9.00007L6.48535 0.514789L12.7206 9.00007L6.48535 17.4854Z"
                fill="currentColor"
            />
        </svg>
        <svg
            v-else
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="6" fill="currentColor" />
        </svg>
    </div>
</template>
